/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC } from "react";
import { graphql } from "gatsby";
import Markdown from "react-markdown";

import { Box, Flex, Card, Image, Heading, Link } from "rebass";

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    subtitle: {
      subtitle: string;
    };
    image: {
      file: {
        url: string;
      };
    };
    link: [
      {
        htmlElement: boolean;
        styledAs: boolean;
        emphasis: boolean;
        slug: string;
        visibleText: string;
      }
    ];
    imagePosition: boolean;
  };
  titleAs?: "h1" | "h2";
  subtitleAs?: "h1" | "h2";
  css?: any;
}

export const query = graphql`
  fragment AlternatingImageSection on ContentfulAlternatingImageSection {
    title
    subtitle {
      subtitle
    }
    link {
      ... on ContentfulLinkButton {
        htmlElement
        styledAs
        emphasis
        slug
        visibleText
      }
    }
    image {
      file {
        url
      }
    }
    imagePosition
    internal {
      type
    }
  }
`;

const AlternatingImageFoundation: FC<Props & JSX.IntrinsicElements["div"]> = ({
  className = "",
  id = "",
  titleAs = "h1",
  subtitleAs = "h2",
  data,
  ...rest
}) => {
  const {
    title,
    subtitle: { subtitle },
    link,
    image,
    imagePosition
  } = data;

  let imageSide = "left";

  if (!imagePosition) {
    imageSide = "right";
  }

  const imageOrder = imageSide === "left" ? 1 : 2;
  const textOrder = imageSide === "left" ? 2 : 1;

  return (
    <Flex overflow="hidden" flexWrap={['wrap', 'wrap', 'no-wrap']}>
      {image && (
        <Box
          sx={{
            backgroundImage: `url(${image.file.url})`,
            backgroundSize: "cover",
            color: "white",
            order: [1, 1, imageOrder]
          }}
          flex={["0 1 100%", "0 1 100%", "0 1 50%"]}
          height={["200px", "200px", "unset"]}
        />
      )}
      <Box
        sx={{ order: [2, 2, textOrder] }}
        width="100%"
        flex={["0 1 100%", "0 1 100%", "0 1 50%"]}
        pb={[4, 0, 0]}
      >
        <Box
          maxWidth={["100%", "100%", "450px"]}
          py={[3, 5]}
          ml={[0, 0, imageSide === "left" ? "0" : "auto"]}
          mr={[0, 0, imageSide === "left" ? "auto" : "0"]}
          pl={[3, 3, imageSide === "left" ? "4%" : "0"]}
          pr={[3, 3, imageSide === "left" ? "0" : "4%"]}
          minHeight={["unset", "unset", "400px"]}
        >
          <Heading as="h2" variant="subheading">{title}</Heading>
          {subtitle && <Markdown source={subtitle} />}
          {link && (
            <Box>
              {link.map(({ slug, visibleText }) => (
                <Link key={slug} href={slug}>
                  {visibleText}
                </Link>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default AlternatingImageFoundation;
