/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC } from "react";
import { graphql } from "gatsby";
import Markdown from "react-markdown";

import { Flex, Box, Text } from "rebass";
import MainContainer from "../../ui-components/container/container";
import { Image } from "../images/images";

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    subtitle: {
      subtitle: string;
    };
    image: any;
  };
  titleAs?: "h1" | "h2";
  subtitleAs?: "h1" | "h2";
  css?: any;
}

export const query = graphql`
  fragment FullScreenImageSection on ContentfulFullScreenImageSection {
    title
    subtitle {
      subtitle
    }
    image {
      ...ContentfulAsset
    }
    internal {
      type
    }
  }
`;

const FullImageFoundation: FC<Props & JSX.IntrinsicElements["div"]> = ({
  className = "",
  id = "",
  titleAs = "h1",
  subtitleAs = "h2",
  data
}) => {
  const { title, subtitle, image } = data;

  return (
    <Box>
      <Image src={image} className="image--full-screen" />
      <MainContainer margin="auto">
        <Box paddingTop={5} paddingBottom={3}>
          <Text as="h1" variant="heading">
            {title}
          </Text>
        </Box>
        <Box width="100%">
          {subtitle && subtitle.subtitle && (
            <Markdown source={subtitle.subtitle} />
          )}
        </Box>
      </MainContainer>
    </Box>
  );
};

export default FullImageFoundation;
